@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.k-grid th {
  padding: 0px 12px !important;
  height: 29px;
  font-family: "NotoSansKR-Bold";
}
.k-grid td {
  padding: 0px 12px !important;
  height: 30px;
  font-family: "Spoqa Han Sans";
  line-height: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임 적용 */
}

.k-grid table {
  width: 100% !important;
}

.k-grid-container {
  overflow: scroll;
}

.k-header {
  vertical-align: middle !important;
  justify-content: center !important;
}

tbody th {
  font-size: 16px !important;
  font-family: "NotoSansKR-Bold";
}

tbody td {
  font-size: 16px !important;
  font-family: "NotoSansKR-Bold";
}

.k-column-title {
  font-size: 16px !important;
  font-family: "NotoSansKR-Bold";
}

.k-button-text {
  /* font-size: 16px !important; */
  font-weight: bold;
  font-family: "NotoSansKR-Bold";
}

.k-child-animation-container > div {
  height: 100% !important;
}

.k-animation-container-shown {
  z-index: 999;
  /* height: "auto"; */
  max-height: 116px !important;
}

/* .k-content {
  overflow: unset !important;
} */

.k-drawer-item.k-selected {
  background-color: var(--kendo-background-color) !important;
}

.k-checkbox:checked {
  background-color: var(--kendo-background-color) !important;
  border-color: var(--kendo-background-color) !important;
}

.k-radio:checked {
  background-color: var(--kendo-background-color) !important;
  border-color: var(--kendo-background-color) !important;
}

.k-link.k-selected {
  color: var(--kendo-background-color) !important;
  background-color: rgb(255 131 122 / 20%) !important;
}
.k-pager-numbers .k-link {
  color: var(--kendo-background-color) !important;
}

.k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header > .k-link {
  color: white !important;
  font-weight: bold !important;
  background-color: var(--kendo-background-color) !important;
}

.k-cell-inner > .k-link {
  padding: 8px 25px 8px 16px !important;
}

.k-animation-container
  > .k-animation-container-relative
  > .k-animation-container-shown {
  max-height: 116px !important;
}
/* .k-grid-content {
  overflow-x: unset !important;
  overflow: unset !important;
} */

.k-pane .k-pane-static .k-scrollable {
  position: relative !important;
}

.k-grid tr.k-selected > td {
  background-color: #d3eeff !important;
}

.k-grid-header .k-header {
  text-align: center !important;
}
